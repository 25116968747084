

const booking = () => {
    return (
        <>
            booking page
            
        </>
    )
}

export default booking
